import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

const Homepage = () => {
  return (
    <div>
      <h1 className="main-heading">Hey!</h1>
      <div className="subheader">
        <StaticImage
          className="bio-avatar"
          layout="fixed"
          formats={['auto', 'webp', 'avif']}
          src="../images/profile-pic.png"
          width={50}
          height={50}
          quality={95}
          draggable={false}
          alt="Profile picture"
        />
        I'm Alex, an engineer and UVA alum based in NYC.
      </div>
      <p>
        I'm a <Link to={'/projects'}>developer</Link>, a{' '}
        <a href="http://contrary.com">startup hype man</a>, and a{' '}
        <a href="https://aig.alumni.virginia.edu/virginiarowing/">rower</a>. I
        love learning new tech and meeting new people.
      </p>
      <p>If anything here sounds interesting to you, I'd love to connect.</p>
    </div>
  );
};

export default Homepage;
