import { graphql } from 'gatsby';
import * as React from 'react';

import Homepage from '../components/homepage';
import Layout from '../components/layout';
import Seo from '../components/seo';

const IndexPath = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="About me" description={''} />
      <Homepage />
    </Layout>
  );
};

export default IndexPath;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`;
